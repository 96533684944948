export function onReady(fn, hx = false) {
  if (
    document.readyState === "complete" ||
    document.readyState === "interactive"
  ) {
    // call on next available tick
    setTimeout(fn, 1)
  } else {
    document.addEventListener("DOMContentLoaded", fn)
  }
  if (hx) {
    document.addEventListener("htmx:afterSettle", fn)
  }
}
