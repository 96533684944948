function showFields(fields, show = false) {
  for (const field of fields) {
    field.closest(".form-group")?.classList.toggle("hide", !show)
  }
}

export function initMembershipForm() {
  const form = document.querySelector(".membership-form")
  if (form) {
    const has_child1_input = form.querySelector("#id_has_child1")
    if (has_child1_input) {
      const fields = form.querySelectorAll('[id^="id_child1"]')
      const inputCheck = (value) => ["ja", "oui", "si"].includes(value)

      showFields(fields, inputCheck(has_child1_input.value))

      has_child1_input.addEventListener("change", (e) => {
        showFields(fields, inputCheck(e.target?.value))
      })
    }

    const field_of_competence = form.querySelector("#id_field_of_competence")
    const field_of_competence_custom = form.querySelector(
      "#id_field_of_competence_custom",
    )
    if (field_of_competence_custom && field_of_competence) {
      field_of_competence.addEventListener("change", (e) => {
        showFields([field_of_competence_custom], e.target?.value === "")
      })
    }
  }
}
